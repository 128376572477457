import React, { HTMLAttributes } from 'react';

interface Props extends HTMLAttributes<SVGElement> {
    active?: boolean;
}

const FilterMobile = ({ active, ...props }: Props) => (
    <svg width="27" height="27" xmlns="http://www.w3.org/2000/svg" xmlLang="http://www.w3.org/1999/xlink" {...props}>
        <defs>
            <filter x="-143.5%" y="-168.8%" width="387%" height="512.5%" filterUnits="objectBoundingBox" id="a">
                <feOffset dy="24" in="SourceAlpha" result="shadowOffsetOuter1" />
                <feGaussianBlur stdDeviation="40" in="shadowOffsetOuter1" result="shadowBlurOuter1" />
                <feComposite in="shadowBlurOuter1" in2="SourceAlpha" operator="out" result="shadowBlurOuter1" />
                <feColorMatrix
                    values="0 0 0 0 0.229949097 0 0 0 0 0.461254077 0 0 0 0 0.62647192 0 0 0 0.0533333333 0"
                    in="shadowBlurOuter1"
                    result="shadowMatrixOuter1"
                />
                <feOffset dy="16" in="SourceAlpha" result="shadowOffsetOuter2" />
                <feGaussianBlur stdDeviation="12" in="shadowOffsetOuter2" result="shadowBlurOuter2" />
                <feComposite in="shadowBlurOuter2" in2="SourceAlpha" operator="out" result="shadowBlurOuter2" />
                <feColorMatrix
                    values="0 0 0 0 0.231372549 0 0 0 0 0.462745098 0 0 0 0 0.62745098 0 0 0 0.0266666667 0"
                    in="shadowBlurOuter2"
                    result="shadowMatrixOuter2"
                />
                <feMerge>
                    <feMergeNode in="shadowMatrixOuter1" />
                    <feMergeNode in="shadowMatrixOuter2" />
                </feMerge>
            </filter>
            <rect id="b" x="0" y="0" width="92" height="64" rx="8" />
        </defs>
        <g transform="translate(-35 -7)" fill="none" fillRule="evenodd">
            <use fill="#000" filter="url(#a)" xlinkHref="#b" />
            {/* @ts-ignore */}
            <rect stroke="#EBEBEB" x=".5" y=".5" width="91" height="63" rx="8" />
            <g stroke="#212A36" strokeLinecap="round" strokeLinejoin="round">
                <path
                    d="M55.3333 14.7117a.6258.6258 0 00-.6233-.6667H37.25a.6247.6247 0 00-.6233.6667c.3012 4.194 3.357 7.6748 7.4766 8.5166v8.2292a.625.625 0 001.025.48l2.5-2.0833a.6233.6233 0 00.225-.48v-6.1459c4.1211-.8402 7.1787-4.3215 7.48-8.5166z"
                    strokeWidth="2"
                />
            </g>
            {active && <circle stroke="#FFF" strokeWidth="2" fill="#EE7845" cx="56" cy="13" r="5" />}
        </g>
    </svg>
);

export default FilterMobile;

import Button from '@oberoninternal/travelbase-ds/components/action/Button';
import { UnreachableCaseError } from '@oberoninternal/travelbase-ds/entities/UnreachableCaseError';
import Map from '@oberoninternal/travelbase-ds/svg/Map.svg';
import { Flex } from '@rebass/grid';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { AllowedSearchAction } from './SearchResults';
import FilterMobile from './svg/FilterMobile';
import ListBullets from './svg/ListBullets.svg';
import Sort from './svg/Sort.svg';

interface SearchBottomBarProps {
    allowedActions: AllowedSearchAction[];
    filtersActive: boolean;
    onMapClick?: () => void;
    onListClick?: () => void;
    onFiltersClick?: () => void;
    onSortClick?: () => void;
}

const SearchBottomBar = ({
    allowedActions,
    filtersActive,
    onMapClick,
    onListClick,
    onFiltersClick,
    onSortClick,
}: SearchBottomBarProps) => (
    <>
        <Container className="lt-l">
            {allowedActions.map(type => {
                switch (type) {
                    case 'filters':
                        return (
                            <StyledButton key={type} onClick={onFiltersClick}>
                                <FilterMobile active={filtersActive} />
                                <span>
                                    <FormattedMessage defaultMessage="filters" />
                                </span>
                            </StyledButton>
                        );
                    case 'map':
                        return (
                            <StyledButton key={type} onClick={() => onMapClick?.()}>
                                <Map />
                                <span>
                                    <FormattedMessage defaultMessage="Kaart" />
                                </span>
                            </StyledButton>
                        );
                    case 'list':
                        return (
                            <StyledButton key={type} onClick={() => onListClick?.()}>
                                <ListBullets />
                                <span>
                                    <FormattedMessage defaultMessage="Lijst" />
                                </span>
                            </StyledButton>
                        );
                    case 'sort':
                        return (
                            <StyledButton key={type} onClick={onSortClick}>
                                <Sort />
                                <span>
                                    <FormattedMessage defaultMessage="sorteren" />
                                </span>
                            </StyledButton>
                        );
                    default:
                        throw new UnreachableCaseError(type);
                }
            })}
        </Container>
    </>
);

export default SearchBottomBar;

const Container = styled(Flex)`
    position: fixed;
    left: var(--wrapperPadding);
    right: var(--wrapperPadding);
    height: 5.6rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: ${({ theme }) => theme.zIndices.sticky};
    > * + * {
        margin-left: 0.6rem;
    }
    bottom: 0.8rem;

    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.s}) {
        height: 6.4rem;
        bottom: 2.4rem;
    }
`;

const StyledButton = styled(Button).attrs({ variant: 'outline' })`
    flex: 3;
    height: 100%;
    border-radius: 0.8rem;
    border: 1px solid ${({ theme }) => theme.colors.neutral['20']};
    :active,
    :focus {
        border-color: ${({ theme }) => theme.colors.neutral['40']};
    }
    && {
        background: ${({ theme }) => theme.colors.neutral['5']};
        box-shadow: 0 1.6rem 2.4rem 0 rgba(59, 118, 160, 0.07), 0 2.4rem 8rem 0 rgba(59, 118, 160, 0.1);
    }

    font-size: 10px !important;

    > span {
        display: flex;
        flex-direction: column;
        text-transform: capitalize;
        > * + * {
            margin-left: 0 !important;
            margin-top: 0.5rem;
        }
    }

    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.s}) {
        font-size: inherit;
    }
`;
